function modifyLink(link, id) {
	const hrefRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/gi;
	link = link.replace(hrefRegex, `<a `);
	return link.replace('<a', `<a style="cursor:pointer;" onclick="javascript:smoothScrollTo('${id}', undefined, -50);"`)
}

export function findAndConvertAnchorLinks(html) {
	// find all anchor links in the html string using regex
	const anchorLinks = html.match(/<a href="#.*">.*<\/a>/g);

	// if there are no anchor links, return the html string
	if (!anchorLinks) {
		return html;
	}

	// loop through each anchor link
	anchorLinks.forEach(link => {
		// add the onclick attribute to the anchor link
		// this will call the dynamicScrollTo function in the global emitter
		// which will scroll to the anchor link
		// don't replace the anchor link if it already has an onclick attribute
		if (link.indexOf('onclick') < 0) {
			// find and store the value of the href attribute
			const href = link.match(/href=".*"/g)[0];
			// find the id of the anchor link
			const id = href.replace('href="#', '').replace('"', '');
			// add the onclick attribute


			// remove href attribute
			html = html.replace(link, modifyLink(link, id));
		}

	});
	return html;
}
