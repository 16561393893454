import Content from 'components/Content';
import {ParallaxChild, ParallaxContainer} from 'hooks/useParallax';
import React from 'react';
import {withTheme} from 'styled-components';
import {Column, Container, Row} from 'system/layout';

function CaseStudyContentBlock({theme, contentNode, content, contentProps = {}}) {
	return (
		<ParallaxContainer>
			<Container>
				<Row>
					<ParallaxChild as={Column} width={[1, 1, 2 / 3]} mx={[0, 0, `auto`]}>
						<Content
							dangerouslySetInnerHTML={{
								__html: contentNode?.childMarkdownRemark?.html || content || ``,
							}}
							fontSize={[14, 16, 18]}
							{...contentProps}
						/>
					</ParallaxChild>
				</Row>
			</Container>
		</ParallaxContainer>
	);
}

export default withTheme(CaseStudyContentBlock);
