import CaseStudyContentBlock from 'blocks/CaseStudyContentBlock';
import Footer from 'components/Footer';
import {graphql} from 'gatsby';
import {HelmetDatoCms} from 'gatsby-source-datocms';
import {withGlobals} from 'globals';
import React, {useEffect, useState} from 'react';
import {withTheme} from 'styled-components';
import theme from 'theme';
import {findAndConvertAnchorLinks} from 'utils/findAndConvertAnchorLinks';
import {resolveLinkByOriginalId} from 'utils/resolveLink';

function createToc(htmlString, locale = 'en') {
	// Helper function to slugify a string
	const slugify = (text) => text.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');

	// Parsing the HTML string
	// const parser = new DOMParser();
	// const doc = parser.parseFromString(htmlString, 'text/html');

	// Building the TOC
	// Find all h2 elements using regex
	const h2Regex = /<h2>(.*?)<\/h2>/g;
	let title = locale === 'fr' ? 'Table des matières' : 'Table of Contents';
	let modifiedHtml = htmlString;
	let toc = `<h2>${title}</h2><ul>`;

	let match;

	while ((match = h2Regex.exec(htmlString)) !== null) {
		const content = match[1];
		const slug = slugify(content);

		// Replace h2 element with the one with an ID
		modifiedHtml = modifiedHtml.replace(match[0], `<h2 id="${slug}">${content}</h2>`);

		// Add to TOC
		// toc += `<li><a href="#${slug}">${content}</a></li>`;
		toc += `<li><button style="color:#001E45; cursor:pointer; appearance: none; border:none; background:none; font-size:1em; " onclick="javascript:smoothScrollTo('${slug}', undefined, -50);">${content}</button></li>`;
	}
	toc += '</ul>';

	modifiedHtml = findAndConvertAnchorLinks(modifiedHtml);
	// Returning the result
	return {modifiedHtml, toc};
}

const contentBlockProps = {
	css: {
		a: {color: '#001E45', fontWeight: 'bold'},
		ul: {
			listStyle: 'disc inside',
			display: 'block',
			marginTop: '1em',
			marginBottom: '1em',
			li: {
				padding: '0.4em 0',
			}
		},
		h2: {fontSize: '2rem', marginTop: '0.75em', marginBottom: '1em'},
		h3: {fontSize: '1.5rem', marginTop: '0.75em', marginBottom: '1em'}
	}
}

function ContentPage({
	                     theme,
	                     data,
	                     footerData,
	                     setPageReady,
	                     setLogoColor,
	                     locale,
                     }) {
	const {
		heading,
		content,
		originalId
	} = data.page;
	const resolvedLink = React.useRef(resolveLinkByOriginalId(originalId, 'en'))
	const useToc = React.useRef(resolvedLink.current.title.toLowerCase().indexOf('privacy') > -1);
	const [processedContentClient, setProcessedContentClient] = useState({modifiedHtml: '', toc: ''});
	let processedContentSSR = {modifiedHtml: '', toc: ''};
	// For server-side rendering, process the HTML directly
	if (useToc.current && typeof window === 'undefined') {
		processedContentSSR = createToc(content, locale);
	}
	useEffect(() => {
		// This code runs only once on the client side after the component mounts
		// Check if the processed content is already set to avoid unnecessary processing
		if (useToc.current && !processedContentClient.modifiedHtml && !processedContentClient.toc) {
			setProcessedContentClient(createToc(content, locale));
		}
	}, []); // Dependency on htmlString to re-run if it changes

	// If the processed content is not set yet, use the server-side processed content
	const processedContent = processedContentClient.modifiedHtml ? processedContentClient : processedContentSSR;
	const {modifiedHtml, toc} = processedContent;

	useEffect(() => {
		setLogoColor(theme.colors.blue);
		setPageReady(true);
	}, [theme.colors.blue, setLogoColor, setPageReady]);

	return (
		<>
			<HelmetDatoCms seo={data.page.seoMetaTags}/>
			{heading && <CaseStudyContentBlock content={`<h1 style="margin-top:3rem">${heading}</h1>`}/>}
			{toc && <CaseStudyContentBlock content={toc} contentProps={contentBlockProps}/>}
			<CaseStudyContentBlock content={modifiedHtml || content} contentProps={contentBlockProps}/>
			<Footer data={footerData} showBg={true}/>
		</>
	);
}

ContentPage.bgColor = theme.colors.white;

export const query = graphql`
  query($uid: String!, $locale: String!) {
    page: datoCmsContentPage(id: { eq: $uid }, locale: { eq: $locale }) {
    id
    title
  originalId
  seoMetaTags {
    ...GatsbyDatoCmsSeoMetaTags
  }
    heading
    content
    }
    }`
;

export default withGlobals(withTheme(ContentPage));
